<!-- <ng-template #contentTemplate> -->
  <div>
    <nz-badge nzColor="green" [nzText]="content.display_name || content.content_attributes.display_name"
    *ngIf="content.auto_requested"></nz-badge>
  <nz-badge nzColor="pink" [nzText]="content.display_name || content.content_attributes.display_name"
    *ngIf="!content.auto_requested"></nz-badge>
    <div *ngIf="content.content_attributes.status !== 'rendering'"
      (click)="showPreview(content.content_attributes)" class="calendar-media">
      <div mat-card-image *ngIf="content.content_attributes.media_type === 'image'">
        <img [src]="content.content_attributes.url" [alt]="content.content_attributes.caption"
          class="internal-content calendar-image" />
      </div>
      <div mat-card-image *ngIf="content.content_attributes.media_type === 'carousel'">
        <img [src]="content.content_attributes.fileURLs[0]" [alt]="content.content_attributes.caption"
             class="internal-content calendar-image" />
      </div>
      <video height="200" width="300" class="internal-content"
        *ngIf="content.content_attributes.media_type === 'video'" autoplay loop [muted]="true" controls>
        <source [src]="content.content_attributes.url" />
      </video>
    </div>
<nz-tabset class="bordered-tabs">
  <nz-tab nzTitle="Details">
    <p class="tb-border"><strong>Status:</strong> {{content.status}}</p>

    <p class="tb-border" *ngIf="content.original_scheduled_at "><strong>Scheduled for:</strong> {{content.original_scheduled_at | date : "MM-dd-y h:mm a"}} <i class="cl-iuie" nz-icon nzType="calendar" nzTheme="outline"></i></p>
    <!--    <p class="tb-border" *ngIf="content.original_scheduled_at "><strong>Orignal Scheduled at:</strong> {{content.original_scheduled_at | date : "MM-dd-y h:mm a"}} <i class="cl-iuie" nz-icon nzType="calendar" nzTheme="outline"></i></p>-->
    <p class="tb-border" *ngIf="content.posted_at"><strong>Posted at:</strong> {{content.posted_at || content.created_at | date : "MM-dd-y h:mma"}}</p>
    <p class="tb-border" *ngIf="content.caption">Caption: {{content.caption.length > 45 ? content.caption.slice(0, 40)+'.....'
      :
      content.caption }}</p>
    <p class="tb-border" *ngIf="!content.caption && (content.content_attributes && !content.content_attributes.caption)">
      Caption: N/A
    </p>
    <p class="tb-border" *ngIf=" content.content_attributes && content.content_attributes.caption">
      <strong>Caption:</strong> {{content.content_attributes.caption.length > 45
      ? content.content_attributes.caption.slice(0, 40)+'.....' :
      content.content_attributes.caption }}</p>
    <p class="tb-border">
      <strong>Posted on :</strong>
      <img *ngIf="content.posted_on && content.posted_on === 'facebook'" class="box-image"
           src=".././../../assets/icons/facebook.png" alt="" />
      <img *ngIf="content.posted_on && content.posted_on === 'linkedin'" class="box-image"
           src=".././../../assets/icons/linkedin.png" alt="" />
      <img *ngIf="content.posted_on && content.posted_on === 'instagram'" class="box-image"
           src=".././../../assets/icons/instagram.png" alt="" />
      <img *ngIf="content.posted_on && content.posted_on === 'google_business'" class="box-image"
           src=".././../../assets/icons/google.png" alt="" />
      <img *ngIf="content.posted_on && content.posted_on === 'youtube'" class="yt-box-image"
           src=".././../../assets/icons/youtube.png" alt="" />
      <img *ngIf="content.posted_on && content.posted_on === 'tiktok'" class="box-image"
           src=".././../../assets/icons/tiktok.png" alt="" />
      <span *ngIf="!content.posted_on">NA</span>
    </p>
    <div *ngIf="content.id" nz-row nzType="flex" nzJustify="space-between" class="actions">
      <button nz-button nzType="default" nzDanger (click)="contentDeleted(content)">
        <i nz-icon nzType="delete"></i>Delete</button>
    </div>
  </nz-tab>
  <nz-tab nzTitle="Posts">
    <nz-table #basicTable [nzData]="posts" [nzFrontPagination]="false">
      <thead>
      <tr>
        <th>Posted At</th>
        <th>Status</th>
        <th>Posted On</th>
        <th>Last Post Attempt </th>
        <th>Caption</th>
        <th>Edit</th>

      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td> <img [src]="'../../../assets/icons/' + data.posted_on + '.png'" class="platform-logo" /> {{data.posted_on | titlecase}}</td>
        <td>{{data.status | titlecase}}</td>
        <td>{{ data.posted_at | date : "MM-dd-y h:mm a"}}</td>
        <td>{{ data.last_post_attempt | date : "MM-dd-y h:mm a"}}</td>
        <td>
          {{data.caption | truncateWords:10 }}
        </td>
        <td>
          <i [ngStyle]="{'color': '#0170f7'}" nz-icon nzType="edit" nzTheme="fill" *ngIf="data.status == 'posting' || data.status == 'rendering' || data.status == 'scheduled'"
             (click)="openEditCaptionModal(data)"></i>
        </td>
      </tr>
      </tbody>
    </nz-table>

  </nz-tab>
</nz-tabset>
    <div nz-row nzType="flex" nzJustify="space-between" class="actions tb-border">
      <div style="margin-top: 20px">
        <p style="color: grey; font-style: italic" *ngIf="content.original_scheduled_at">
          {{content.original_scheduled_at | date : "MM-dd-y"}}
        </p>
      </div>
      <div style="margin-top: 15px">
        <button nz-button nzType="primary" class=" pull-right" (click)="close()">Close</button>
      </div>


    </div>
<!-- </ng-template> -->
<ng-template #titleTemplate>
  <nz-badge nzColor="green" [nzText]="content.display_name || content.content_attributes.display_name"
    *ngIf="content.auto_requested"></nz-badge>
  <nz-badge nzColor="pink" [nzText]="content.display_name || content.content_attributes.display_name"
    *ngIf="!content.auto_requested"></nz-badge>
</ng-template>
<!-- <button nz-button nzSize="large" nzType="default" nz-popover [nzPopoverTitle]="titleTemplate"
  [nzPopoverContent]="contentTemplate" nzPopoverTrigger="hover" [ngStyle]="{'background-color': content.auto_requested ? '#52c41a' : '#eb2e95', 'color': content.auto_requested ? '#52c41a' : '#eb2e95' }">
  <img *ngIf="content.posted_on && content.posted_on === 'facebook'" class="platform-image"
    src=".././../../assets/icons/facebook_new.png" alt="" />
  <img *ngIf="content.posted_on && content.posted_on === 'linkedin'" class="platform-image"
    src=".././../../assets/icons/linkedin_new.png" alt="" />
  <img *ngIf="content.posted_on && content.posted_on === 'instagram'" class="platform-image"
    src=".././../../assets/icons/instagram_new.png" alt="" />
  {{content.display_name || content.content_attributes.display_name}}

</button> -->

