import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CardButtonConfig } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { map } from 'rxjs/operators'; `1`
import { Subject, Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Content } from 'src/app/vos/content/content';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Customer } from 'src/app/vos/customer/customer';
import { BreadCrumService } from '../../services/breadcrum/bread-crum.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ContentsService } from '../../services/contents/contents.service';
import { DownloaderService } from 'src/app/services/downloader.service';
import { ContentPreviewDialogComponent } from '../../shared/content-preview-dialog/content-preview-dialog.component';
import { SocialPostDialogComponent } from '../../shared/social-post-dialog/social-post-dialog.component';
import { GenericDialogComponent } from '../../shared/generic-dialog/generic-dialog.component';
import { ContentApprovalModalComponent } from '../../shared/content-approval-modal/content-approval-modal.component';
import { ContentPreviewItemComponent } from './content-preview-item/content-preview-item.component';
import moment from 'moment';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GlobalsService } from '../../services/globals/globals.service';
import { ProductsService } from '../../services/products/product-api.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy {
  @ViewChild(ContentPreviewItemComponent)
  private contentPreviewItemComponent: ContentPreviewItemComponent;

  calendarHeader = {
    right: 'dayGridMonth,timeGridWeek,timeGridDay',
    center: 'title',
    left: 'prev next today',
  };

  selectedIndex = 0;
  selectedContent: number;
  currentContentId;
  notificationId: number;
  dateFilter;
  queuedData = [];
  scheduledContent = [];
  newId;
  approval;
  contentUpdateSubscription: Subscription;
  contentTypes = ['All', 'Published', 'Unpublished'];
  selectedContentType = 'All';
  filterOptions = [
    'Listing Videos',
    'Listing Flyers',
    'Open House Videos',
    'Open House Flyers',
    'Congrats Videos',
    'Instagram Ad',
    'Commercial Listing Video',
    //
    'Video Banners',
    'Marketing Videos',
    'Testimonial Videos',
    'Testimonial Flyers',
    'Social Media Content',
    // 'Branded Infomercials',
    //
    'Local Market Video',
    'Single Data Snapshot',
    'Market Update Flyer',
    'Cover Photo'
  ];

  filters = {
    all: true,
    Listing: false,
    Marketing: false,
    'Market Report': false,
    'Listing Videos': false,
    'Listing Flyers': false,
    'Open House Videos': false,
    'Open House Flyers': false,
    'Congrats Videos': false,
    'Instagram Ad': false,
    'Commercial Listing Video': false,
    //
    'Video Banners': false,
    'Marketing Videos': false,
    'Testimonial Videos': false,
    'Testimonial Flyers': false,
    // 'Branded Infomercials': false,
    'Social Media Content': false,
    //
    'Local Market Video': false,
    'Single Data Snapshot': false,
    'Market Update Flyer': false,
    'Cover Photo': false
  };
  tabs = ['All', 'Published', 'Unpublished', 'Content Calendar'];
  currentTab = 0;
  loading = true;
  products: CardButtonConfig[];
  contents: Content[];
  calenderContents: any;
  customer: Customer;
  pageSizeOptions = [10];
  searchText: string;
  contentPagination = {
    limit: 10,
    total: 100,
    page: 1,
    totalPages: 10
  };
  panels = [
    {
      active: true,
      name: 'Listing',
      disabled: false,
      checkbox: false,
      childFilters: [
        { name: 'Listing Videos', abbreviation: 'LV', value: false, filter_value: 8 },
        { name: 'Listing Flyers', abbreviation: 'LF', value: false, filter_value: 5 },
        { name: 'Open House Videos', abbreviation: 'OH', value: false, filter_value: 6 },
        { name: 'Open House Flyers', abbreviation: 'OF', value: false, filter_value: 14 },
        { name: 'Congrats Videos', abbreviation: 'CG', value: false, filter_value: 4 },
        { name: 'Instagram Ad', abbreviation: 'LVS', value: false, filter_value: 11 },
        { name: 'Commercial Listing Video', abbreviation: 'CLV', value: false, filter_value: 20 },
      ]
    },
    {
      active: true,
      disabled: false,
      name: 'Marketing',
      checkbox: false,
      childFilters: [
        { name: 'Video Banners', abbreviation: 'VB', value: false, filter_value: 0 },
        { name: 'Marketing Videos', abbreviation: 'MV', value: false, filter_value: 1 },
        { name: 'Testimonial Videos', abbreviation: 'TS', value: false, filter_value: 13 },
        { name: 'Testimonial Flyers', abbreviation: 'TSF', value: false, filter_value: 18 },
        // { name: 'Branded Infomercials', abbreviation: 'BIF', value: false, filter_value: 17 },
        { name: 'Social Media Content', abbreviation: 'BIF', value: false, filter_value: 17 },
        { name: 'Cover Photo', abbreviation: 'CF', value: false, filter_value: 21 },
      ]
    },
    {
      active: true,
      disabled: false,
      name: 'Market Report',
      checkbox: false,
      childFilters: [
        { name: 'Local Market Video', abbreviation: 'DV', value: false, filter_value: 7 },
        { name: 'Single Data Snapshot', abbreviation: 'DVS', value: false, filter_value: 12 },
        // { name: 'Year to Date Video', abbreviation: 'DVY', value: false, filter_value: 16 },
        { name: 'Market Update Flyer', abbreviation: 'DVSY', value: false, filter_value: 15 },
      ]
    }
  ];
  checked: true;
  selectedValue = 'all';
  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;
  constructor(
    private authService: AuthenticationService,
    private breadcrumService: BreadCrumService,
    private contentService: ContentsService,
    private downloader: DownloaderService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private cdrf: ChangeDetectorRef,
    private globalsService: GlobalsService,
    private productsService: ProductsService,
  ) { }

  ngOnInit() {
    this.contentChannelSub();
    this.activatedRoute.queryParams.subscribe(params => {
      this.selectedContent = params['selectedContent'];
      this.notificationId = params['notification'];
      this.approval = params['approval'];

      if (params.status === 'published') {
        this.currentTab = 1;
      } else if (params.status === 'unpublished') {
        this.currentTab = 2;
      } else if (params.status === 'scheduled') {
        this.currentTab = 3;
      }
      if (params.id) {
        this.currentContentId = params.id;
        this.selectedContent = this.currentContentId;
      }
      if (params.new) {
        this.newId = params.new;
      }
      if (this.selectedContent) {
        this.openContentApprovalModal();
      }
      if (this.notificationId) {
        this.notificationService.mark_read(this.notificationId).subscribe(response => { });
      }
    });
    this.parseSelectedFiltersFromRouter();
    // this.tabs = this.globalsService.getContentTabs();
    this.customer = this.authService.currentCustomerValue;
    const params: Record<string, any> = {
      per_page: 40,
    };
    this.productsService.productList(`customers/${this.customer?.id}/products`, params).subscribe(
      (res) => {
        if (res) {
          this.products = res.data;
          const googleProduct = res.data ? res.data?.find((p) => p.abbreviation === 'GMB' && p.locked === false) : null;
          if (googleProduct && googleProduct != null) {
            this.globalsService.setIsGMBAllowed(true);
          } else {
            this.globalsService.setIsGMBAllowed(false);
          }
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
    this.breadcrumService.set_breadcrum();
    this.fetchContent();
    this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(newText => {
        this.searchText = newText;
        if (newText) {
          this.fetchContent(`&q[contentable_of_MarketReport_type_zip_code_or_contentable_of_MarketReport_type_region_name_or_contentable_of_Listing_type_address_cont]=${newText}`);
        } else {
          this.fetchContent();
        }
        this.router.navigate([], {
          queryParams: {
            text: newText ? newText : null
          },
          queryParamsHandling: 'merge'
        });
      });
  }

  contentChannelSub() {
    this.contentUpdateSubscription = this.contentService.contentUpdateChannel()
      .received().subscribe(res => {
        if(res) {
        const contentResponse = res?.data;
        const contentIndex = this.contents?.findIndex(c => c.id === contentResponse.content_id);
        if (contentIndex > -1) {
          this.contents[contentIndex].status = contentResponse.status;
          if (this.contents[contentIndex].last_post) {
            this.contents[contentIndex].last_post.status = contentResponse.status;
          }
        }
        const quedContentIndex = this.queuedData?.findIndex(c => c.content_id === contentResponse.content_id && c.id === contentResponse.post_id);
        if (quedContentIndex > -1) {
          this.queuedData[quedContentIndex].status = contentResponse.status;
          // this.queuedData[quedContentIndex].posted_at = contentResponse.posted_at;
          this.mapCalendarData();
        }
      }
      })
  }

  resetFilter(type, panelType?) {
    this.falseAllFilters();
    this.filters[type] = true;
    this.changeFilter(type, panelType);
  }

  falseAllFilters() {
    Object.keys(this.filters).forEach(key => {
      this.filters[key] = false;
    })
  }
  changeFilter(type, panelType?) {

    setTimeout(() => {
      let value = this.filters[type];
      this.falseAllFilters();
      this.filters[type] = value;
      this.panels = this.panels.map(panel => {
        if (panel.name == panelType && this.filters[type]) {
          panel.checkbox = true;
        } else {
          panel.checkbox = false;
        }
        panel.childFilters.forEach(filter => {
          if ((filter?.name == panelType && this.filters[type]) || panel.name == panelType) {
            filter.value = true;
          } else {
            filter.value = false;
          }
          // filter.value = panel.checkbox;
        });
        return panel;
      })
      this.updateSingleChecked();
    }, 200);

  }

  contentDeleted(content: Content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete content?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.contentService.destroy(content)
          .subscribe(res => {
            this.contents = this.contents?.filter(c => c.id !== content.id);
            this.message?.create('success', `Content has been successfully deleted.`);
            this.fetchContent();
          }, e => {
            this.loading = false;
            this.message?.remove();
            this.message?.create('error', 'Error deleting the content. Please try again');
          });
      }
    });
  }

  onChangeDateFilter(event) {
    if (event) {
      this.router.navigate([], {
        queryParams: {
          created_at_gteq: this.dateFilter.length ? moment(this.dateFilter[0]).format('yyyy-MM-DD') : null,
          created_at_lteq: this.dateFilter.length ? moment(this.dateFilter[1]).format('yyyy-MM-DD') : null,
        },
        queryParamsHandling: 'merge'
      });
      this.fetchContent();
    }
  }

  cancelSchedule(content: Content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to cancel your scheduled post?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.contentService.unschedulPost(content)
          .subscribe(res => {
            this.contents = this.contents?.filter(c => c.id !== content.id);
            this.message?.create('success', `Your scheduled post is cancelled`);
            this.fetchContent();
          }, e => {
            this.loading = false;
            this.message?.remove();
            this.message?.create('error', 'Error cancelling the scheduled post. Please try again');
          });
      }
    });
  }

  selectContent(content: Content) {
    this.currentContentId = content.id;
    this.newId = null;
    // this.contentPreviewItemComponent.showPreview(content);
    this.router.navigate([], {
      queryParams: {
        id: this.currentContentId,
        new: null
      },
      queryParamsHandling: 'merge'
    });
  }

  onClosePreview(event) {
    this.currentContentId = null;
    this.selectedContent = null;
  }

  didPage(pageIndex) {
    this.contentPagination.page = pageIndex;
    this.fetchContent();
  }

  changeTypeSelection() {
    this.resetFilter(this.selectedValue || 'all', this.selectedValue);
  }

  fetchContent(searchQuery?) {
    this.loading = true;
    const filters = this.getSelectedFilters();
    let queryParams = `?page=${this.contentPagination.page}`;
    if (filters.length > 0) {
      queryParams = queryParams + this.mapFilters(filters);
    }
    if (searchQuery) {
      queryParams = queryParams + searchQuery;
    } else if (this.searchText) {
      queryParams = `${queryParams}&q[contentable_of_MarketReport_type_zip_code_or_contentable_of_MarketReport_type_region_name_or_contentable_of_Listing_type_address_cont]=${this.searchText}`
    }

    if (this.dateFilter && this.dateFilter.length) {
      queryParams = `${queryParams}&q[created_at_gteq]=${this.dateFilter[0]}&q[created_at_lteq]=${this.dateFilter[1]}`
    }
    let type = '';
    if (this.currentTab === 1) {
      type = '/published';
      this.getContentData(queryParams, type)
    } else if (this.currentTab === 2) {
      type = '/unpublished'
      this.getContentData(queryParams, type)
    } else if (this.currentTab === 3) {
      // type = '/queued';
      this.getQueuedData(queryParams);

    } else {
      this.getContentData(queryParams, type)
    }

  }

  getQueuedData(queryParams) {
    this.loading = true;
    const pageSize = 100;
    if (this.contentPagination.page === 1) {
      this.queuedData = []
    }
    this.contentService.postSchedule(`${queryParams}&per_page=${pageSize}&page=${this.contentPagination.page}`)
      .subscribe(res => {
        this.queuedData = [...this.queuedData, ...res.data];
        this.queuedData = this.queuedData.map(entry => {
          entry.isQueued = true;
          return entry;
        });
        if (res['total_entries'] > pageSize && this.queuedData.length < res['total_entries']) {
          this.contentPagination.page = this.contentPagination.page + 1;
          this.getQueuedData(queryParams);
          return;
        }
        // this.calenderContents = {};
        // this.queuedData.forEach(content => {
        //   let key = content.scheduled_for ? moment(content.scheduled_for).format('YYYY-MM-DD') : moment(content.created_at).format('YYYY-MM-DD');
        //   if (this.calenderContents[key]) {
        //     this.calenderContents[key].push(content);
        //   } else {
        //     this.calenderContents[key] = [content];
        //   }
        // });
        // this.loading = false;
        this.getScheduledContent(queryParams);
      });
  }

  getContentData(queryParams, type) {
    this.contentService.list(queryParams, type)
      .pipe(
        map((res) => {
          this.contentPagination.limit = res['per_page'];
          this.contentPagination.total = res['total_entries'];
          const limit = this.contentPagination.limit;
          const total_items = this.contentPagination.total;
          this.contentPagination.totalPages = Math.ceil(total_items / limit);
          return res.data;
        })
      ).subscribe(contents => {
        this.contents = contents;
        this.loading = false;
      });
  }

  getScheduledContent(queryParams) {
    this.loading = true;
    this.contentService.calenderSchedule(queryParams)
      .subscribe(contents => {
        this.scheduledContent = contents.data;
        this.mapCalendarData();
        // this.calenderContents = {};
        // contents.data.forEach(content => {
        //   let key = moment(content.scheduled_for).format('YYYY-MM-DD');
        //   if (this.calenderContents[key]) {
        //     this.calenderContents[key].push(content);
        //   } else {
        //     this.calenderContents[key] = [content];
        //   }
        // });
        // this.queuedData.forEach(content => {
        //   let key = content.scheduled_for ? moment(content.scheduled_for).format('YYYY-MM-DD') : moment(content.created_at).format('YYYY-MM-DD');
        //   if (this.calenderContents[key]) {
        //     this.calenderContents[key].push(content);
        //   } else {
        //     this.calenderContents[key] = [content];
        //   }
        // });
        this.loading = false;
      });
  }

  mapCalendarData() {
    this.calenderContents = {};

    this.scheduledContent.forEach(content => {
      let key = moment(content.scheduled_for).format('YYYY-MM-DD');
      if (this.calenderContents[key]) {
        this.calenderContents[key].push(content);
      } else {
        this.calenderContents[key] = [content];
      }
    });

    this.queuedData.forEach(content => {
      let key = content.scheduled_for ? moment(content.scheduled_for).format('YYYY-MM-DD') : moment(content.created_at).format('YYYY-MM-DD');
      if (this.calenderContents[key]) {
        this.calenderContents[key].push(content);
      } else {
        this.calenderContents[key] = [content];
      }
    });

  }

  log(args: any[]): void {
    this.searchText = '';
    this.fetchContent();
  }

  download(content) {
    const newContenr = new Content(content);
    let url = content?.url || '';
    if (url) {
    let file_type_array = url?.split('.')
    let file_type = file_type_array[file_type_array.length - 1];
    let filename = newContenr.filename || `download.${file_type}`;
      if (!url.includes('https')) {
        url = url.replace('http', 'https');
      }
      this.downloader.save(url, filename);
    }
  }
  post(content) {
    if (content.status === 'ready') {
      const modal = this.modalService.create<SocialPostDialogComponent, IModalData>({
        nzContent: SocialPostDialogComponent,
        nzData: {
          content: content
        },
        nzFooter: null
      });
      // modal.afterClose.subscribe(response => {
      // });
    }
  }

  sort(sort: { key: string; value: string }) {
    const sortName = sort.key || 'descend';
    this.contents?.sort((a, b) =>
      sort.value === 'ascend'
        ? a[sortName!] > b[sortName!]
          ? 1
          : -1
        : b[sortName!] > a[sortName!]
          ? 1
          : -1
    );
  }
  showPreview(content, isEdit?) {
    const that = this;
    const modal = this.modalService.create<ContentPreviewDialogComponent, IModalData>({
      nzTitle: content.display_name,
      nzContent: ContentPreviewDialogComponent,
      nzData: {
        config: {
          content,
          isEdit,
          refresh: (updatedContent) => {
            const selectedContentIndex = that.contents?.findIndex(c => c.id === updatedContent.id);
            that.contents[selectedContentIndex] = updatedContent;
          }
        }
      },
      nzFooter: null,
      nzWidth: '50%',
      // nzBodyStyle: {
      //   'height': '65vh',
      //   'overflow': 'scroll'
      // }
    });
    modal.afterClose.subscribe(response => {
      if (response && response.action === 'deleted') {
        this.fetchContent();
      }
      this.currentContentId = null;
      this.selectedContent = null;
      this.router.navigate([], {
        queryParams: {
          id: null
        },
        queryParamsHandling: 'merge'
      });
    });
  }

  gotToEdit(content) {
    this.router.navigate([`listings/${content.contentable_id}/edit`]);
  }
  openContentApprovalModal() {
    this.contentService.show(this.selectedContent).subscribe(response => {
      if (this.approval === 'true') {
        this.openApprovalModal(response.data);
      } else {
        this.showPreview(response.data);
      }
    }, error => {
      this.router.navigate(['NotFound']);
    });
  }
  openApprovalModal(content) {
    const modal = this.modalService.create<ContentApprovalModalComponent, IModalData>({
      nzContent: ContentApprovalModalComponent,
      nzFooter: null,
      nzData: {
        content: content
      }
    });
    this.cdrf.detectChanges();
    modal.afterClose.subscribe(closed => {
      this.cdrf.detectChanges();
      this.router.navigate(['content']);
    });
  }
  panelCheckboxUpdate(name) {
    const category = this.panels.find(panel => panel.name === name);
    const cf = category.childFilters.find(f => f.value === false);
    if (cf) {
      return false;
    } else {
      return true;
    }
  }
  updateSingleChecked() {
    const filters = this.getSelectedFilters();
    let params = '';
    filters.forEach(filter => {
      if (params.length > 0) {
        params = params + '&' + filter.abbreviation;
      } else {
        params = filter.abbreviation;
      }
    });
    if (params.length < 1) {
      this.router.navigate([], {
        queryParams: { content_category_eq: null },
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigate([], {
        queryParams: { content_category_eq: params },
        queryParamsHandling: 'merge'
      });
    }
    this.fetchContent();
  }
  updateAllChecked(panel) {
    panel.childFilters.forEach(filter => {
      filter.value = panel.checkbox;
    });
    this.updateSingleChecked();
  }
  getSelectedFilters() {
    let filters = [];
    this.panels.forEach(panel => {
      const panelFilters = panel?.childFilters?.filter(childfilter => childfilter.value === true);
      if (panel.childFilters.length === panelFilters.length) {
        panel.checkbox = true;
      } else {
        panel.checkbox = false;
      }
      filters = filters.concat(panelFilters);
    });
    return filters;
  }
  mapFilters(filters) {
    let params = '';
    filters.forEach(filter => {
      params = params + '&q[category_in][]=' + filter.filter_value;
    });
    return params;
  }
  truncateChar(text: string): string {
    const charlimit = 25;
    if (!text || text.length <= charlimit) {
      return text;
    }
    const shortened = text.substring(0, charlimit) + '...';
    return shortened;
  }

  changeIndex(contentType) {
    // this.currentTab = index;
    this.newId = null;
    let queryParams = {
      status: null,
      new: null
    };
    this.contentPagination.page = 1;
    if (contentType === 'Published') {
      queryParams = { status: 'published', new: null };
    } else if (contentType === 'Unpublished') {
      queryParams = { status: 'unpublished', new: null };
    }
    // else if (index === 3) {
    //   queryParams = { status: 'scheduled', new: null };
    // }
    this.router.navigate(['/content'],
      {
        queryParams,
        queryParamsHandling: 'merge'
      });
    this.fetchContent();
  }

  clearSubscriptions() {
    if (this.contentUpdateSubscription) {
      this.contentUpdateSubscription.unsubscribe();
    }
  }
  ngOnDestroy() {
    this.clearSubscriptions();
  }

  parseSelectedFiltersFromRouter() {
    let filters = '';
    this.activatedRoute.queryParams.subscribe(params => {
      filters = params['content_category_eq'];
      if (params['created_at_gteq'] && params['created_at_lteq']) {
        this.dateFilter = [new Date(params['created_at_gteq']), new Date(params['created_at_lteq'])];
      }
      if (params['text']) {
        this.searchText = params['text'];
      }
    });
    if (filters && filters.length > 0) {
      filters.split('&').forEach(filter => {
        this.panels.forEach(panel => {
          panel.checkbox = false;
          panel.childFilters.forEach(cf => {
            if (cf?.abbreviation === filter) {
              cf.value = true;
              panel.checkbox = true;
            }
            if (panel.checkbox) {
              this.selectedValue = panel.name;
            } else if (this.selectedValue !== panel.name) {
              this.selectedValue = this.selectedValue || 'all';
            }
          });
        });
      });

    }
  }

  get showGMB() {
    return this.globalsService.getIsGMBAllowed();
  }
}
