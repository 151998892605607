import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {CustomerResourceService} from '../customer-resource.service';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication/authentication.service';
import {Customer} from '../../vos/customer/customer';


@Injectable()

export class ConfigService extends CustomerResourceService<any> {
  public endpoint = 'config';
  public data_key = 'config';

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Customer, http, authService);
  }
  show(){
    const url = `${environment.api_url}/api/v1/${this.endpoint}.json`;
    return this.http.get<DataResponse<any>>(url).pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
