import { Component, OnInit, Input, inject } from '@angular/core';
import { MarketReportsService } from 'src/app/services/market-reports/market-reports.service';
import { MarketReport} from 'src/app/vos/market-report/market-report';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-market-report-form-modal',
  templateUrl: './market-report-form-modal.component.html',
  styleUrls: ['./market-report-form-modal.component.scss']
})
export class MarketReportFormModalComponent implements OnInit {
  zipData: any;
  loading = false;
  error: string;
  errorRegion: string;
  isNew = true;
  currentStep = 1;
  propertyType: string;
  dataPoints = [];
  propertyTypes = [{key: 'residential', name: 'Residential'},
    {key: 'NonResidential', name: 'Non Residential'},
    {key: 'singleFamily', name: 'Single Family'},
    {key: 'condominium', name: 'Condominium'},
    {key: 'townHouse', name: 'Town House'},
    {key: 'multifamily', name: 'Multifamily'},
    {key: 'apartment', name: 'Apartment'},
    {key: 'all', name: 'All'}];
  emptyData = {
    Zip: '99501',
    LastMonthYear: '',
    PresentMonthYear: '',
    Title1: 'Median Price Per Sq. Ft.',
    DataDisplay1A: '',
    DataDisplay1B: '',
    Title2: 'Available Listings',
    DataDisplay2A: '',
    DataDisplay2B: '',
    Title3: 'Median List Price',
    DataDisplay3A: '',
    DataDisplay3B: '',
    Title4: 'Average Days on Market',
    DataDisplay4A: '',
    DataDisplay4B: '',
    Title5: 'Least Expensive Listing',
    DataDisplay5A: '',
    DataDisplay5B: '',
    Title6: 'Most Expensive Listing',
    DataDisplay6A: '',
    DataDisplay6B: '',
  };
  @Input()
  marketReport =  new MarketReport();
  @Input()
  originalMarketReport: MarketReport;
  
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IModalData = inject(NZ_MODAL_DATA);
  private options = {
    headers: {
      'X-Hide-Error-Notifications': 'true'
    }
  };
  constructor(private marketReportService: MarketReportsService,
              private nzModalRef: NzModalRef,
              private router: Router,
              private route: ActivatedRoute,
              public message: NzMessageService,
  ) {
    this.dataPoints = Array.from({length: 25}, ( v, k) => k + 1 );

    this.zipData = this.emptyData;
  }

  ngOnInit() {
    if (this.nzModalData.marketReport) {
      this.originalMarketReport = this.nzModalData.marketReport;
      this.marketReport = JSON.parse(JSON.stringify(this.originalMarketReport));
    } else {
      this.marketReport = new MarketReport();
    }
    
    if (this.marketReport.id) {
      this.isNew = false;
      this.searchZip();
    }
  }

  searchZip() {
    this.error = null;
    if (this.marketReport.location_attributes.zip_code) {
      this.loading = true;
      this.zipData = this.emptyData;
      this.marketReportService.search(this.marketReport.location_attributes.zip_code, [], this.propertyType, this.options).subscribe(response => {
        this.zipData = response;
        this.loading = false;
      }, error => {
        const zCode = this.marketReport.location_attributes.zip_code;
        this.error = error.error && error.error.zip_code ? zCode + ' ' + error.error.zip_code[0] : error.error ;
        this.loading = false;
      });
    } else {
      this.error = 'Zip code is required.';
    }
  }
  closeModal(updatedReport: MarketReport | null = null) {
    this.#modal.close(updatedReport);
  }

  submit() {
    if (this.marketReport.location_attributes.zip_code) {
      this.loading = true;
      let request: Observable<MarketReport>;
      if (this.isNew) {
        request = this.marketReportService.create(this.marketReport, this.options);
      } else {
        request = this.marketReportService.update(this.marketReport, false, this.options);
      }
      request.subscribe((res) => {
        this.loading = false;
        this.closeModal(res);
        this.message?.create(
          "success",
          `Market report ${this.isNew ? 'created' : 'updated'} successfully`
        );
        // this.router.navigate(['/my_markets', res.id]);
        // this.currentStep = 2;
      }, error => {
        this.loading = false;
        this.error = error.error;
      });
    } else {
      this.error = 'Zip code is required.';
    }
  }

  checkForm(type = null) {
    if (type === 'region') {
      setTimeout(() => {
        if(this.marketReport.region_name?.length === 30) {
          this.errorRegion = 'Maximum 30 characters reached'; 
        }else {
          this.errorRegion = null;
        }
      }, 0);
    }
    
    if (type === 'zip') {
      const isnum = /^\d+$/.test(this.marketReport.location_attributes.zip_code);
      if (!isnum) {
        this.error = 'Please enter number only!';
      } else {
        this.error = null;
      }
    } else {
      this.error = null;
    }
  }
}
