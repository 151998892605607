
// This file was autogenerated by dynamically running setEnv.ts and using dotenv for managing API key secrecy
export const environment = {
  production: true,
  api_url: 'https://dev-api.zentap.com/',
  email_api_url: 'undefined',
  env_string:  'staging',
  wss_url: 'wss://dev-api.zentap.com/cable',
  bugSnagApiKey: 'd21c584de432752d6fdc40b6b1e142f0',
  stripePublicKey: 'pk_test_H08I0vKtG0Oaz28ZktMtQaQ0',
  linkedin_configs: {
    linkedin_url: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
    redirect_uri: 'https://dev.zentap.com/linkedin',
    client_id: '869yyt2403h0rs',
    scope: 'r_emailaddress r_ads rw_ads r_basicprofile r_liteprofile w_organization_social r_organization_social rw_organization_admin w_member_social'
  },

  // facebook app credentails
  facebook_app_configs: {
    client_id: '210551162312621',
    facebook_url: 'https://www.facebook.com/v15.0/dialog/oauth?',
    redirect_uri: 'https://dev.zentap.com/facebook',
  },
  aws: {
    LOCATION: 'v1.public.eyJqdGkiOiIxYThhY2U2Ni1lMjY1LTQwZjUtODU3Ny04OWU5ZDExMGE0NTQifUCy6ZE9z2-DdE0ZaDz26qObLQlZHsZtSAKGG7DXm3fULl7j1DF7CFcbytpfvKfRRXPm9bBqusA4IYxnkcI5wi8HeP1xZ_Jywp9xLBQaioFccwUwcvinfknmbV0Eofb5u-24rb3XpSv-iUJolLDVnR_clJ2SZBT84OvxzCkUmLyyQvIiAZjLJ-3y1NencdSRUeUV-AS7v_cOWHZnnDy23j55-b5z0ctnH04duuyhFfliGEFOQ8Qc9Nrw3xJZkiJPF8WqqgXOMp4aqmPkrhzZKdiCSk2QABN_62GcjZre3ENiQHikFTl24DI_-rZ5nwZasNY7ztRvAH_U8si76dPDcqk.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx'
  },
  google_app_configs: {
    client_id: '1012309178141-bm3ods1it40i6ain67an9u4edm98sj64.apps.googleusercontent.com',
    project_id: 'undefined',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth?',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_secret: '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',
    redirect_uri: 'https://dev.zentap.com/google',
    youtube_redirect_uri: 'https://dev.zentap.com/youtube'
  },
   tiktok_app_configs: {
    tiktok_client_key: 'awi9mblwq0zqwpkc',
    tiktok_client_SECRET: 'd3a2479fdcaf6b9945967d54210c6a6d',
    tiktok_app_id: '7221462579254904837',
    redirect_uri: 'https://dev.zentap.com/tiktok'
  },
  cloudinary_uploads: {
    cloud_name: 'undefined',
    api_url: 'undefined',
    unsigned_preset: 'undefined',
    srt_signed_preset: 'undefined',
    secret: 'undefined',
    api_key: 'undefined'
  }
};
