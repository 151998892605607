import {
  Component, OnInit, Input, ViewChild,
  ElementRef, Output, EventEmitter, OnDestroy
} from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Content } from 'src/app/vos/content/content';
import { Customer } from 'src/app/vos/customer/customer';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ContentsService } from 'src/app/services/contents/contents.service';
import { BrandedInfographicsService } from 'src/app/services/branded-infogrpahics/branded-infographics.service';

import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { ActionCableService, Channel } from 'angular2-actioncable';

import * as FileSaver from 'file-saver';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { DownloaderService } from 'src/app/services/downloader.service';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { SocialPostDialogComponent } from '../social-post-dialog/social-post-dialog.component';
import { ContentReceiptDialogComponent } from '../content-receipt-dialog/content-receipt-dialog.component';
import { ContentPreviewDialogComponent } from '../../shared/content-preview-dialog/content-preview-dialog.component';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit, OnDestroy {
  @ViewChild('captioninput') captioninput: ElementRef;
  subscription: Subscription;
  @Input()
  content: Content = new Content();

  customer: Customer;
  @Input()
  width = 'auto';

  @Input()
  horizontalMargin = '24px';

  @Input()
  verticalMargin = '24px';

  @Input()
  caption_editable = false;

  @Input()
  showTopMenu = true;

  @Input()
  showMoreMenu = false;

  @Output()
  posted = new EventEmitter<Content>();
  isPlay = false;
  editing = false;
  loading = false;
  initialWidth: string;
  checked = false;
  canPost = false;
  elem: ElementRef;
  visible: boolean;
  isInfomercials: boolean = false;
  isSocialMediaContent: boolean = false;
  isMarketingVideo: boolean = false;
  isMarketingFlyer: boolean = false;
  isEmailSign: boolean = false;


  confirmModal?: NzModalRef;
  @Output()
  editingChange = new EventEmitter();

  @Output()
  deleted = new EventEmitter<Content>();
  // get canPost(): boolean {
  //   return !this.customer.settings.auto_posting && [ 'ready', 'posting', 'posted' ].includes(this.content.status);
  // }

  get height(): string {
    if (this.width === 'auto') {
      return 'auto';
    } else {
      return '' + parseInt(this.width?.replace(/\D/g, ''), 10) * 296 / 282 + 'px';
    }
  }
  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private cableService: ActionCableService,
    private contentService: ContentsService,
    private brandedInfographicsService: BrandedInfographicsService,
    private downloader: DownloaderService,
    private modalService: NzModalService,
    private message: NzMessageService,
    element: ElementRef,
    private notification: NzNotificationService
  ) {
    this.elem = element;
  }

  ngOnInit() {
    this.content.caption = '';
    this.isInfomercials = this.router.url.includes('social_media_content');
    this.isSocialMediaContent = this.router.url.includes('social_media_content');

    this.isMarketingVideo = this.router.url.includes('marketing_videos');
    this.isMarketingFlyer = this.router.url.includes('marketing_flyers');
    this.isEmailSign = this.router.url.includes('email_signature');


    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
        this.canPost = this.customer ?
          !this.customer.settings.auto_posting && ['ready', 'posting', 'posted', 'promoted'].includes(this.content.status) : false;
        this.initialWidth = this.width;
      }
    });
  }
  showPreview() {
    window.open(this.content.url, 'nil');
  }
  createContent() {
    // this.openCreatePreview(this.content);

    if (this.isMarketingVideo || this.isMarketingFlyer || this.isEmailSign) {
      this.openCreatePreview(this.content);
      // this.postMarketingVideo();
    } else if (this.isSocialMediaContent) {
      this.showCreateConfirm();
    }
  }

  postInforgraphic() {
    // this.showPostConfirm();
    this.openCreatePreview(this.content, true);
    // this.post();
  }

  postMarketingVideo() {
    this.loading = true;
    this.editing = false;
    const request = this.contentService.create({
      category: this.content.category,
      caption: this.content.caption,
      style_name: this.content.style,
      contentable_type: this.content.contentable_type,
      contentable_id: this.content.contentable_id,
      extra_attributes: this.content.extra_attributes
    });
    request.subscribe(c => {
      this.loading = false;
      const is_template = this.content.is_template;

      // this.message.create('success', 'successfully created, we will notify when its created!');
      const config = {
        type: 'marketing_videos',
        link: 'branding/marketing_videos/new'
      };
      this.notification.create(
        'success',
        'Created',
        'Content has successfully been created'
      );
      this.router.navigateByUrl(`/content?new=${c.id}`);
      // this.receipt(config, `/content?content_category_eq=${this.content.category}`);
    });
  }

  createBrandedInformercial() {
    this.loading = true;
    this.editing = false;

    const request = this.brandedInfographicsService.create({
      infographic_id: this.content.id,
      caption: this.content.caption
    });
    request.subscribe(c => {
      this.loading = false;
      const is_template = this.content.is_template;

      // this.message.create('success', 'successfully created, we will notify when its created!');
      const config = {
        type: 'social_media_content',
        link: 'social_media_content/new'
      };
      this.notification.create(
        'success',
        'Created',
        'Content has successfully been created'
      );
      this.router.navigateByUrl(`/content?new=${c.id}`);
      // this.receipt(config, `/content?content_category_eq=${this.content.category}`);
    }, err => {
      this.message?.remove();
      this.message?.create('error', 'Error creating Branded Infomercial.');
    });
  }

  openCreatePreview(content, isPost?) {
    const modal = this.modalService.create<ContentPreviewDialogComponent, IModalData>({
      nzTitle: content.display_name,
      nzContent: ContentPreviewDialogComponent,
      nzData: {
        config: {
          content,
          isEdit: true,
          isCard: true,
          isMarketingVideo: this.isMarketingVideo || this.isMarketingFlyer || this.isEmailSign,
          isInfomercials: this.isSocialMediaContent,
          isEmailSign : this.isEmailSign,
          isPost
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {

    });
  }

  // createBrand() {
  //   this.createContent();
  //   // if (!this.content.caption) {
  //   // } else {
  //   //   this.createContent();
  //   // }
  // }

  postBrand() {
    this.showPostConfirm();
    // if (!this.content.caption) {
    // } else {
    //   this.post();
    // }
  }
  showPostConfirm(): void {
    this.confirmModal = this.modalService.confirm({
      nzTitle: 'Before you Post...',
      nzOkText: 'Post Content',
      nzCancelText: 'Go Back',
      nzContent: `<p>Posting this content will not add your business card information to the end of the informercial.</p>
      <b>If you wish to continue press "Post Content".<b/>`,
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.post();
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!'))
    });
  }

  showCreateConfirm(): void {
    this.confirmModal = this.modalService.confirm({
      nzTitle: 'Before you Create...',
      nzOkText: 'Make Content',
      nzCancelText: 'Go Back',
      nzContent: `<p>Creating this content will add your business card information to the end of the informercial.</p>
      <b>If you wish to continue press "Make Content".<b/>`,
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // this.createBrandedInformercial();
          this.openCreatePreview(this.content);
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!'))
    });
  }

  post() {
    const modal = this.modalService.create<SocialPostDialogComponent, IModalData>({
      nzContent: SocialPostDialogComponent,
      nzData: {
        content: this.content
      },
      nzFooter: null
    });
    modal.afterClose.subscribe(response => {
    });
  }
  create() {
    this.loading = true;
    const request = this.contentService.create({
      category: this.content.category,
      caption: this.content.caption,
      style_name: this.content.style_name,
      contentable_type: this.content.contentable_type,
      contentable_id: this.content.contentable_id
    });
    request.subscribe(c => {
      this.loading = false;
      // const is_template = this.content.is_template;
      this.content.status = 'rendering';
      this.message.create('success', 'successfully created, we will notify when its created!');
    });
  }

  download() {
    let url = this.content.url;
    if (!url.includes('https')) {
      url = url.replace('http', 'https');
    }
    this.downloader.save(url, this.content.filename);
    // FileSaver.saveAs(url, this.content.filename);
  }

  setEditing(editing: boolean) {
    this.editing = editing;
    setTimeout(() => {
      if (this.captioninput) {
        this.captioninput.nativeElement.focus();
      }
      if (window?.scrollY > 0) {
        window.scrollTo(0, 0);
      }
      // this.elem.nativeElement.focus();
    }, 1000);
  }
  // receipt(config, redirectUrl) {
  //   this.router.navigateByUrl(redirectUrl);
  //   const modal = this.modalService.create({
  //     nzContent: ContentReceiptDialogComponent,
  //     nzData: {
  //       config
  //     },
  //     nzFooter: null,
  //     nzWidth: '40%'
  //   });
  // }
  delete() {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this content?',
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.contentService.destroy(this.content).subscribe(r => {
          this.deleted.emit(this.content);
          this.loading = false;
        }, e => {
          this.loading = false;
          this.message?.remove();
          this.message?.create('error', 'Error deleting this content. Please try again');
        });
      }
    });
  }

  isVideo(url) {
    const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv', 'flv'];
    const extension = url.split('.').pop().toLowerCase();

    if (videoExtensions.includes(extension)) return true;
    else return false;
  }

  playVideo() {
    this.isPlay = true;
  }

  ngOnDestroy(): void {
  }

}
