import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {Observer, Subscription} from 'rxjs';
import Swiper, { Autoplay  } from 'swiper';

import { UpgradeDialogComponent } from '../../shared/upgrade-dialog/upgrade-dialog.component';
import { ConnectLinkedinService } from '../../services/connect-linkedin/connect-linkedin.service';
import { CustomersService } from '../../services/customers/customers.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { DownloaderService } from 'src/app/services/downloader.service';
import { ContentsService } from '../../services/contents/contents.service';
import { BrandedInfographicsService } from 'src/app/services/branded-infogrpahics/branded-infographics.service';
import { CustomersFacebookService } from '../../services/customers-facebook/customers-facebook.service';
import { FacebookOnboardingDialogComponent } from '../../shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import { GoogleOnboardingDialogComponent } from '../../shared/google-onboarding-dialog/google-onboarding-dialog.component';

import { GenericDialogComponent } from '../../shared/generic-dialog/generic-dialog.component';
import { Customer } from '../../vos/customer/customer';
import moment from 'moment-timezone';
import { CaptionsService } from '../../services/captions/captions.service';
import { ConnectInstagramService } from '../../services/connect-instagram/connect-instagram.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { CustomersGoogleService } from '../../services/customers-google/customers-google.service';
import { ProductsService } from '../../services/products/product-api.service';

Swiper.use([Autoplay]);
import AWSS3UploadAshClient from 'aws-s3-upload-ash';
import { UploadResponse } from 'aws-s3-upload-ash/dist/types';
import { environment } from '../../../environments/environment';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import { Content } from 'src/app/vos/content/content';
import {CustomContentService} from '../../services/custom-contents/custom-content.service';
import * as uuid from 'uuid';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import {Observable} from 'rxjs/internal/Observable';
import {ConfigService} from '../../services/config/config.service';




@Component({
  selector: 'app-content-preview-dialog',
  templateUrl: './custom-content-dialog.component.html',
  styleUrls: ['./custom-content-dialog.component.scss']
})
export class CustomContentDialogComponent implements OnInit, OnDestroy {

  @ViewChild('caption', { static: false }) captionInput: ElementRef;

  socialConfig;
  submitted = false;
  showActions = true;
  config: any;
  isEdit = true;
  isRescheduled = false;
  content = new Content();
  isUnscheduled = false;
  schedule_time: Date;
  schedule_date: Date;
  customer: Customer;
  all = false;
  facebook = false;
  linkedin = false;
  instagram = false;
  google = false;
  isFacebookConnected = false;
  customClientS3;
  isGoogleConnected = false;
  hideGoogle = false;

  integration;
  linkedinStatus = false;
  linkedinAutopost = false;
  linkedinConnected = false;

  instagramIntegration;
  instagramStatus = false;
  instagramAutopost = false;
  instagramConnected = false;

  loading = false;
  status = false;
  showCaption = true;
  showEmojiPicker = false;
  set = 'twitter';
  PaidTier = false;
  hideFacebook = true;
  hidePosting = false;
  isScheduledPost = false;
  isDateLoading = false;
  facebookSuccess = false;
  linkedinSuccess = false;
  previewVisible = false;
  previewImage;
  fileUploaded = false;
  blockedUpload = false;
  image = false;
  video = false;
  branded = false;
  isHashtagLimit = false;
  privacyStatus;
  hashtags: string[] = [];
  contentUpdateSubscription: Subscription;
  errorValidation;
  uploadMultiple = false;
  outsideClick = false;
  isCancelled = false;
  fileList: NzUploadFile[] = []
  fileRender: NzUploadFile[] = []
  disabledHours(): number[] {
    return this.schedule_date && new Date(this.schedule_date).getDate() - new Date().getDate() < 1
      ? this.range(1, new Date().getHours() + 12) : [];
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and after 90 days
    const dateDiff = current && (current.getDate() - new Date().getDate() < 1) && (new Date().getHours() > 12) ? -1 : 0
    const daysDiff = differenceInCalendarDays(new Date(), current);
    return daysDiff > dateDiff || differenceInCalendarDays(current, new Date()) > 60;
  };

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  constructor(
    private contentsService: ContentsService,
    private modal: NzModalRef,
    private downloader: DownloaderService,
    private modalService: NzModalService,
    private router: Router,
    private message: NzMessageService,
    private authService: AuthenticationService,
    private linkedInService: ConnectLinkedinService,
    private instagramService: ConnectInstagramService,
    private customersService: CustomersService,
    private customContentService: CustomContentService,
    private brandedInfographicsService: BrandedInfographicsService,
    private notification: NzNotificationService,
    private captionsService: CaptionsService,
    private customersFacebookService: CustomersFacebookService,
    private globalsService: GlobalsService,
    private customersGoogleService: CustomersGoogleService,
    private productsService: ProductsService,
    private configService: ConfigService,
  ) { }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
      const isVideo = file.type === 'video/mp4' || file.type === 'video/mpeg';

      let failedValidations: string[] = [];
      let maxFileSizeMB = 2; // Default to 2MB for images

      if (isImage) {
        if (this.socialConfig.facebook) {
          if (file.size! / 1024 / 1024 > 4) {
            failedValidations.push('Facebook (Image max 4MB)');
          }
        }
        if (this.socialConfig.linkedin) {
          if (file.size! / 1024 / 1024 > 8) {
            failedValidations.push('LinkedIn (Image max 8MB)');
          }
        }
        if (this.socialConfig.instagram) {
          if (file.size! / 1024 / 1024 > 8) {
            failedValidations.push('Instagram (Image max 8MB)');
          }
        }
        if (this.socialConfig.google) {
          if (file.size! / 1024 / 1024 > 5) {
            failedValidations.push('Google (Image max 5MB)');
          }
        }
        if (this.socialConfig.youtube) {
          if (file.size! / 1024 / 1024 > 2) {
            failedValidations.push('YouTube (Image max 2MB)');
          }
        }
      } else if (isVideo) {
        if (this.socialConfig.facebook) {
          if (file.size! / 1024 / 1024 > 1024) {
            failedValidations.push('Facebook (Video max 1024MB)');
          }
        }
        if (this.socialConfig.linkedin) {
          if (file.size! / 1024 / 1024 > 200) {
            failedValidations.push('LinkedIn (Video max 200MB)');
          }
        }
        if (this.socialConfig.instagram) {
          if (file.size! / 1024 / 1024 > 100) {
            failedValidations.push('Instagram (Video max 100MB)');
          }
        }
        if (this.socialConfig.google) {
          if (file.size! / 1024 / 1024 > 100) {
            failedValidations.push('Google (Video max 100MB)');
          }
        }
        if (this.socialConfig.youtube) {
          if (file.size! / 1024 / 1024 > 128) {
            failedValidations.push('YouTube (Video max 128MB)');
          }
        }
      } else {
        // this.msg.error('You can only upload JPG/PNG images or MP4/MPEG videos!');
        observer.complete();
        return;
      }

      if (failedValidations.length > 0) {
        this.errorValidation = failedValidations;
        this.message.create('error', `Validation failed for: ${failedValidations.join(', ')}`);
        observer.complete();
        return;
      }

      observer.next(true);
      observer.complete();
    });

  ngOnInit() {
    this.configService.show().subscribe(resp => {

      const config = {
        bucketName: resp['BUCKET'],
        dirName: 'customContent',
        region: resp['REGION'],
        accessKeyId: resp['AWS_ACCESS_KEY'],
        secretAccessKey: resp['AWS_SECRET_KEY'],
        s3Url: `https://${resp['BUCKET']}.s3.${resp['REGION']}.amazonaws.com/`
      };
      this.customClientS3 = new AWSS3UploadAshClient(config);
    });
    this.checkCustomerConectedFacebookPage();

    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
        this.PaidTier = c?.tier === 'ultra';
        this.facebook = this.customer?.settings?.auto_posting;
        this.fetch_linkedin_autopost_settings();
        this.fetch_instagram_autopost_settings();
        this.setSocialContentConfig();
      }

    });
    this.checkLinkedin();
    this.fetchCustomerProducts();
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const modals = document.querySelectorAll('.ant-modal-content');
    const selectElement = document.querySelector('.ant-select-dropdown');
    const emojiElement = document.querySelector('.emoji-mart');
    const datePicker  = document.querySelector('.schedule-picker-date-dropdown');
    const timePicker = document.querySelector('.schedule-picker-time-dropdown');

    const clickedInsideSelect = selectElement && selectElement.contains(event.target as Node);
    const clickedInside = Array.from(modals).some((modal) => modal.contains(event.target as Node));
    const clickedInsideEmoji = emojiElement && emojiElement.contains(event.target as Node);
    const clickedInsideDatePicker = datePicker && datePicker.contains(event.target as Node);
    const clickedInsideTimePicker = timePicker && timePicker.contains(event.target as Node);
    
    if(clickedInsideEmoji === null && this.outsideClick){
      this.outsideClick = false;
    }
    
    if (!clickedInside && !this.outsideClick && !clickedInsideSelect && !clickedInsideEmoji && !clickedInsideTimePicker && !clickedInsideDatePicker) {
      this.modal.triggerCancel();
    }
  }

  removeAllFiles(): void {
    this.fileList = [];
  }

  uploadFile = async (item: any) => {
    try {
      const fileType = item.file.type;
      const isImage = fileType === 'image/png' || fileType === 'image/jpeg';
      const fileName = `${uuid.v4()}.${fileType.split('/')[1]}`;

      this.image = isImage;
      this.video = !isImage;
      this.outsideClick = true;
      this.blockedUpload = true;

      const data: UploadResponse = await this.customClientS3.uploadFile(
        item.file,
        fileType,
        undefined,
        fileName,
        'private'
      );

      if (!this.isCancelled) {
        this.content.url = data.location;
        this.isCancelled = false;
        this.fileUploaded = true;
      } else {
        this.fileUploaded = false;
        this.isCancelled = false;
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      this.outsideClick = false;
      this.blockedUpload = false;
    }
  };

  uploadedFiles: any[] = [];

  uploadFiles = async (items: any) => {
    if (!Array.isArray(items)) {
      items = [items];
    }
    this.image = true;
    this.outsideClick = true;
    for (let item of items) {
      const fileType = item.file.type;
      let fileName = `${uuid.v4()}.${fileType.split('/')[1]}`;
      try {
        const data: UploadResponse = await this.customClientS3
          .uploadFile(item.file, fileType, undefined, fileName, 'private')
            if(!this.isCancelled){
              this.isCancelled = false;
              this.fileUploaded = true;
              const newFile = {
                url: data.location,
                type: fileType.includes('image') ? "image" : "video",
              };
              this.uploadedFiles = this.uploadedFiles.concat(newFile);
              this.fileList = this.fileList.filter((file) => file.uid !== item.uid);
            } else {
              this.fileUploaded = false;
              this.isCancelled = false;
              this.uploadedFiles = [];
              break
            }
      } catch (err) {
        console.error("Error uploading file:", err);
      }finally {
        this.outsideClick = false;
      }
    }
    if((!this.uploadMultiple && this.outsideClick) || (items.length != this.uploadFiles.length) ){
      this.fileUploaded = false;
    }
  };

  onFileChange(event: any) {
    const files = Array.from(event.target.files);
    this.uploadFiles(files);
  }

  setSocialContentConfig() {
    const content = this.content;
    const hideGoogle = content?.media_type === 'video';
    this.socialConfig = { content: content, facebook: false, linkedin: false, youtube: false, google: false, hideGoogle };
    this.socialConfig.facebook = this.customer?.settings?.auto_posting;
  }


  checkCustomerConectedFacebookPage() {
    this.customersFacebookService.getCustomerConectedFacebookPage()
      .subscribe(res => {
        if (res) {
          const { user_id_on_provider = null } = res?.body?.data?.length > 0 ? res.body.data[0] : {};
          if (!user_id_on_provider && !this.customer.fb_page_id) {
            this.isFacebookConnected = false;
          } else {
            this.isFacebookConnected = true;
          }
        }
      });
  }
  showFacebnook() {
    const modal = this.modalService.create<FacebookOnboardingDialogComponent, IModalData>({
      nzContent: FacebookOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
    });
  }

  connectLinkedIn() {
    this.linkedInService.authorizeLinkedIn();
  }

  connectInstagram() {
    this.instagramService.create({}).subscribe((c) => {
      this.notification.create(
        'success',
        'Connected',
        'Instagram successfully connected!'
      );
    }, err => {
      this.message.create('error', `Error creating Instagram connection. ${err}`);
    });
  }
  checkLinkedin() {
    localStorage.removeItem('linkedInCode');
    this.customersService.getLinkedin().subscribe((c) => {
      this.linkedinConnected = (c.length > 0);
    });
  }

  showGoogle() {
    const modal = this.modalService.create<GoogleOnboardingDialogComponent, IModalData>({
      nzContent: GoogleOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true
        }
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe(response => {
      this.checkCustomerConectedGoogleBusiness();
      // this.fetch_instagram_autopost_settings();
    });
  }

  checkCustomerConectedGoogleBusiness() {
    this.customersGoogleService.getGoogleUserConnection()
      .subscribe(res => {
      if (res) {
        const { user_id_on_provider = null, meta_data = null } = res?.body?.data?.length > 0 ? res.body.data[0] : {};
        if (!user_id_on_provider && !meta_data?.location_id_on_provider) {
          this.isGoogleConnected = false;
        } else {
          this.isGoogleConnected = true;
        }
      }
    });
  }

  changeAll() {
    this.facebook = this.all;
    this.linkedin = this.all;
    this.instagram = this.all;
    this.google = this.all;
    if (this.isCarousel) {
      this.linkedin = false;
      this.google = false;
    }
  }

  refreshContent() {
    if (this.config.content.id && !this.config.isInfomercials && !this.config.isMarketingVideo) {
      this.contentsService.show(this.config.content.id)
        .subscribe(res => {
        if (res) {
          this.content = res.data;
          if (!this.config.content) {
            this.config = { ...this.config, content: {} }
          }
          this.config.content = JSON.parse(JSON.stringify(this.content));
          if (this.config && this.config.refresh)
            this.config.refresh({ ...res.data });
          this.loading = false;
        }
      });
    }
  }
  render() {
    if (this.config.isCard && this.config.isMarketingVideo && !this.config.isPost) {
      this.postMarketingVideo(null, true);
    } else if (this.config.isCard && this.config.isInfomercials && !this.config.isPost) {
      // this.createBrandedInformercial(null, true);
    }
  }

  postMarketingVideo(platforms?, skip_post?) {
    this.loading = true;
    const request = this.contentsService.create({
      category: this.content.category,
      caption: this.content.caption,
      style_name: this.content.style_name,
      contentable_type: this.content.contentable_type,
      contentable_id: this.content.contentable_id,
      scheduled_for: this.content.scheduled_for,
      extra_attributes: this.content.extra_attributes,
      platforms,
      skip_post
    });
    request.subscribe(c => {
      this.loading = false;
      const is_template = this.content.is_template;

      // this.message.create('success', 'successfully created, we will notify when its created!');
      const config = {
        type: 'marketing_videos',
        link: 'branding/marketing_videos/new'
      };
      this.removeDialog();
      this.notification.create(
        'success',
        'Created',
        'Content has successfully been created'
      );
      this.router.navigateByUrl(`/content?new=${c.id}`);
      // this.receipt(config, `/content?content_category_eq=${this.content.category}`);
    }, err => {
      this.loading = false;

    });
  }

  createCustomContent(platforms?, skip_post?) {
    this.loading = true;
    this.submitted = true;
    if (this.uploadMultiple) {
      const request = this.customContentService.create({
        caption: this.content.caption,
        url: '',
        custom_image_urls: this.uploadedFiles.map((file) => file.url),
        scheduled_for: this.content.scheduled_for,
        branded: this.branded,
        extra_attributes: {
          title: this.content.title,
          privacy_status: this.privacyStatus,
        },
        platforms,
        skip_post,
      });
      request.subscribe(
        (c) => {
          this.loading = false;
          const is_template = this.content.is_template;
          this.removeDialog();
          this.notification.create(
            'success',
            'Created',
            'Content has successfully been created'
          );
          this.router.navigateByUrl(`/content?new=${c.id}`);
        },
        (err) => {
          this.loading = false;
        }
      );
    } else {
      const request = this.customContentService.create({
        caption: this.content.caption,
        url: this.content.url,
        scheduled_for: this.content.scheduled_for,
        branded: this.branded,
        extra_attributes: { title: this.content.title , privacy_status: this.privacyStatus },
        platforms,
        skip_post
      });
      request.subscribe(c => {
          this.loading = false;
          const is_template = this.content.is_template;

          // const config = {
          //   type: 'social_media_content',
          //   link: 'social_media_content/new'
          // };
          // this.refreshContent();
          this.removeDialog();
          this.notification.create(
            'success',
            'Created',
            'Content has successfully been created'
          );
          this.router.navigateByUrl(`/content?new=${c.id}`);
        }, err => {
          this.loading = false;
          // this.message.remove();
          // this.message.create('error', 'Error creating Branded Infomercial.');
        });
    }
  }

  post(event : Event) {
    event.stopPropagation();
    const platforms = [];
    if (this.socialConfig.facebook) {
      platforms.push('facebook');
    }
    if (this.socialConfig.linkedin) {
      platforms.push('linkedin');
    }

    if (this.socialConfig.instagram) {
      platforms.push('instagram');
    }
    if (this.socialConfig.google) {
      platforms.push('google_business');
    }
    if (this.socialConfig && this.socialConfig.youtube) {
      platforms.push('youtube');
    }
    if (this.socialConfig && this.socialConfig.tiktok) {
      platforms.push('tiktok');
    }

    if (platforms.length === 0) {
      this.message?.remove();
      this.message?.create('error', 'Please select platform!');
      return;
    }

    // if (this.config.isCard && this.config.isMarketingVideo && !this.config.isPost) {
    //   this.postMarketingVideo(platforms);
    // } else if (this.config.isCard && this.config.isInfomercials && !this.config.isPost) {
    this.createCustomContent(platforms);
    // }
  }

  postLN() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'linkedin',
        scheduled_for: this.content.scheduled_for
      }
    }, 'posts');

    request.subscribe(c => {
      const caption = this.content.caption;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      this.resetContent();
      this.linkedinSuccess = true;

      // this.message.create('success', 'Your linkedin post has been posted Sucessfully');
      this.status = true;
      this.content.status = 'posted';
      this.isEdit = true;
      this.isScheduledPost = false;
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your linkedin post has been failed');
      this.status = false;
    });
  }
  postFB() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'facebook',
        scheduled_for: this.content.scheduled_for
      }
    }, 'posts');
    request.subscribe(c => {
      const caption = this.content.caption;
      this.loading = false;
      this.content = c['content_attributes'];
      this.content.caption = caption;

      // this.message.create('success', 'Your facebook post has posted Sucessfully');
      this.resetContent();
      this.status = true;
      this.isEdit = true;
      this.isScheduledPost = false;
      // }
      this.content.status = 'posted';
      // });
      // this.message.create('success', 'successfully requested your posting, we will notify when its posted!');
      this.status = true;
      this.facebookSuccess = true;
      // this.posted.emit(c);
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your facebook post has failed');
      this.status = false;
    });
  }
  postInstagram() {
    const uid = `${this.content.id}`;
    const request = this.contentsService.createPost({
      'uid': uid,
      'post': {
        'content_id': this.content.id,
        'caption': this.content.caption,
        'posted_on': 'instagram',
        scheduled_for: this.content.scheduled_for
      }
    }, 'posts');

    request.subscribe(c => {
      const caption = this.content.caption;
      this.content = c['content_attributes'];
      this.content.caption = caption;
      this.resetContent();
      this.linkedinSuccess = true;

      // this.message.create('success', 'Your linkedin post has been posted Sucessfully');
      this.status = true;
      this.content.status = 'posted';
      this.isEdit = true;
      this.isScheduledPost = false;
    }, (error) => {
      this.message?.remove();
      // this.message.create('error', 'Your linkedin post has been failed');
      this.status = false;
    });
  }
  removeDialog() {
    this.modal.close();
  }

  fetch_linkedin_autopost_settings() {
    this.customersService.getLinkedin().subscribe((c) => {
      if (c && c.length > 0) {
        this.linkedinStatus = true;
        this.linkedInService.getAutopost().subscribe(int => {
          this.integration = int.data[0];
          this.linkedinAutopost = this.integration.auto_posting;
          this.linkedin = this.linkedinAutopost && this.PaidTier;
          this.facebook = this.customer?.settings?.auto_posting;
          this.instagram = this.instagramAutopost && this.PaidTier;
          this.all = this.linkedin && this.facebook && this.instagram;
        });
      }
    });
  }
  fetch_instagram_autopost_settings() {
    this.customersService.getInstagram().subscribe((c) => {
      if (c.length > 0) {
        this.instagramStatus = true;
        // this.linkedInService.getAutopost().subscribe(int => {
        this.instagramIntegration = c[0];
        this.instagramConnected = true;
        this.instagramAutopost = this.instagramIntegration.auto_posting;
        this.instagram = this.instagramAutopost && this.PaidTier;
        this.linkedin = this.linkedinAutopost && this.PaidTier;
        this.facebook = this.customer.settings.auto_posting;
        this.all = this.linkedin && this.facebook && this.instagram;
        // });
      }
    });
  }

  upgradePlan() {
    this.modal.close();
    const modal = this.modalService.create({
      nzContent: UpgradeDialogComponent,
      nzFooter: null
    });
  }
  facebookSupport() {
    this.modal.close();
    const modal = this.modalService.create<UpgradeDialogComponent, IModalData>({
      nzContent: UpgradeDialogComponent,
      nzFooter: null,
      nzWidth: 600,
      nzData: {
        facebookSupport: true
      },
    });
  }

  cancelSchedule() {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to cancel your scheduled post?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.loading = true;
        this.contentsService.unschedulPost(this.config.content)
          .subscribe(res => {
            this.refreshContent();
            this.loading = false;
            this.notification.create(
              'success',
              'Scheduled post cancelled',
              'Your scheduled post is cancelled'
            );
          }, e => {
            this.loading = false;
            this.message?.remove();
            this.notification.create(
              'error',
              'Cancelling scheduled post',
              'Error cancelling the scheduled post. Please try again'
            );
          });
      }
    });
  }

  resetContent() {
    this.refreshContent();
  }


  toggleEmojiPicker() {
    setTimeout(() => {
     this.showEmojiPicker = !this.showEmojiPicker;
     this.outsideClick = true;
    });
  }



  download() {
    const content = this.config.content;
    let url = content.url;
    if (url && !url.includes('https')) {
      url = url?.replace('http', 'https');
    }
    this.downloader.save(url, content.filename);
  }
  enablePost() {
    this.isEdit = true;
  }

  // post() {
  //   if (this.config.content.status === 'ready') {
  //     const modal = this.modalService.create({
  //       nzContent: SocialPostDialogComponent,
  //       nzData: {
  //         content: this.config.content
  //       },
  //       nzFooter: null
  //     });
  //     modal.afterClose.subscribe(response => {
  //     });
  //   }
  // }

  gotToEdit() {
    this.modal.close();
    this.router.navigate([`listings/${this.config.content.contentable_id}/edit`]);
  }

  changeDate(current) {
    if (current) {
      this.content.scheduled_for = new Date(current);
      if (this.content.last_post) {
        this.content.last_post.scheduled_for = this.content.scheduled_for;
      }
    } else if (!this.schedule_time) {
      this.schedule_time = null;
    }
    if (this.schedule_time && current) {
      const scheduleDate = new Date(current);
      this.schedule_time = new Date(this.schedule_time);
      this.schedule_time.setDate(scheduleDate.getDate());
      this.schedule_time.setMonth(scheduleDate.getMonth());
      this.schedule_time.setFullYear(scheduleDate.getFullYear());
      const tempDate = moment(this.schedule_time);
      this.content.scheduled_for = `${tempDate.format('yyyy-MM-DD')}T${tempDate.format('HH:mm:ssZ')}`;
      if (this.content.last_post) {
        this.content.last_post.scheduled_for = this.content.scheduled_for;
      }
    }
    // if (this.schedule_date) {
    //   this.schedule_time = this.schedule_time ? new Date(this.schedule_time) : new Date(current);
    //   this.schedule_time.setDate(this.schedule_date.getDate());
    //   this.schedule_time.setMonth(this.schedule_date.getMonth());
    //   this.schedule_time.setFullYear(this.schedule_date.getFullYear());
    // } else {
    //   this.schedule_time = null;
    // }


  }
  isPosted(content) {
    // console.log(content.last_post.scheduled_for)

  }

  changeTime(current) {
    this.schedule_date = new Date(this.schedule_date);
    if (this.schedule_date.getDate() - new Date().getDate() < 1 &&
      this.schedule_date.getMonth() <= new Date().getMonth() &&
      this.schedule_date.getFullYear() <= new Date().getFullYear() &&
      current &&
      current.getHours() - new Date().getHours() < 6) {
      // this.schedule_time = new Date(current);
      this.schedule_time = null;
      this.message.create('error', 'You must schedule posts to be at least 6 hours from content creation');
      // this.message.create('error', 'You cannot select time before 12 hours');
    } else {
      this.schedule_time = new Date(current);
    }
    if (this.schedule_time && this.schedule_date) {
      this.schedule_time.setDate(this.schedule_date.getDate());
      this.schedule_time.setMonth(this.schedule_date.getMonth());
      this.schedule_time.setFullYear(this.schedule_date.getFullYear());
      const tempDate = moment(this.schedule_time).tz('America/California');
      this.content.scheduled_for = `${tempDate.format('yyyy-MM-DD')}T${tempDate.format('HH:mm:ssZ')}`;
      if (this.content.last_post) {
        this.content.last_post.scheduled_for = this.content.scheduled_for;
      }
    }
  }

  addEmoji(event) {
    const { caption = '' } = this.content;
    const text = `${caption}${event.emoji.native}`;
    this.content.caption = text;
    this.captionInput.nativeElement.focus();
    this.captionInput.nativeElement.click();
    this.showEmojiPicker = false;
  }

  showEdit() {
    this.isEdit = true;
  }

  cancelEdit() {
    this.isEdit = true;
    this.isScheduledPost = false;
  }
  showReschedule() {
    this.isEdit = true;
    this.isScheduledPost = true;
    this.schedule_date = this.config.content.last_post.scheduled_for;
    this.schedule_time = this.config.content.last_post.scheduled_for;
  }

  onChangeScheduleStatus(event) {
    if (this.isUnscheduled) {
      this.schedule_time = null;
      this.schedule_date = null;
      this.content.scheduled_for = null;
    } else {
      this.schedule_time = this.config.content.scheduled_for;
      this.schedule_date = this.config.content.scheduled_for;
      this.content.scheduled_for = this.config.content.scheduled_for;
      if (this.content.last_post) {
        this.content.last_post.scheduled_for = this.content.scheduled_for;
      }
    }
  }

  contentDeleted(content) {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        title: 'Confirm',
        message: `Are you sure you want to delete content?`,
        buttonLabel: 'Cancel',
        extraButtons: [
          {
            label: 'Confirm',
            value: true,
            color: 'warn'
          }
        ]
      },
      nzFooter: null,
      nzWidth: '60%'
    });
    modal.afterClose.subscribe(response => {
      if (response === true) {
        this.contentsService.destroy(content)
          .subscribe(res => {
            this.message.create('success', `Content has been successfully deleted.`);
            this.modal.close({ action: 'deleted' });
          }, e => {
            this.message?.remove();
            this.message?.create('error', 'Error deleting the content. Please try again');
          });
      }
    });
  }


  onSave() {
    if (this.isUnscheduled) {
      this.unSchedule();
    } else if (this.config.content.scheduled_for && this.config.content.scheduled_for !== this.content.scheduled_for) {
      this.reSchedule();
    } else if (this.content.caption) {
      this.contentsService.update(this.content)
        .subscribe(res => {
          this.config.content = this.content;
          this.message.create('success', 'You post is saved!');

        }, err => {
          this.message.create('error', 'Error whiel updating post');

        });
    }
    // TODO: update content caption if there is change in caption

  }

  unSchedule() {
    this.contentsService.unschedulPost(this.content)
      .subscribe(res => {
        this.isEdit = false;
        this.config.content = this.content;
        this.message.create('success', 'You post is unscheduled!');
      });
  }
  finalReschedule() {
    if (this.facebook && this.config.content.status === 'ready') {
      this.reSchedule('facebook');
    }
    if (this.linkedin && this.config.content.status === 'ready') {
      this.reSchedule('linkedin');
    }
  }

  reSchedule(posted_on?) {
    const contentPayload = { ...this.content };
    contentPayload.posted_on = posted_on || this.content.last_post.posted_on;

    this.contentsService.reschedulPost(contentPayload)
      .subscribe(res => {
        this.isEdit = true;
        this.isRescheduled = true;
        this.refreshContent();
        this.message.create('success', 'You post is rescheduled!');
      }, err => {
        // this.notification.create(
        //   'error',
        //   'Error',
        //   'Something went wrong!'
        // );
      });
  }
  clearSubscriptions() {
    if (this.contentUpdateSubscription) {
      this.contentUpdateSubscription.unsubscribe();
    }
  }
  ngOnDestroy() {
    this.clearSubscriptions();
  }
  get getLinkedInCode() {
    const payload = this.linkedInService.getAuthorizationCode();
    if (payload) {
      this.linkedinConnected = (payload && payload.status === 'success');
      // this.cdr.detectChanges();
    }
    return payload;
  }

  get isDateChanged() {
    return this.content?.scheduled_for !== this.config?.content?.last_post?.scheduled_for;
  }

  get isCarousel(): boolean {
    return this.content?.media_type.toLowerCase() === 'carousel';
  }


  fetchCustomerProducts() {
    if (this.globalsService?.getIsGMBAllowed) {
      return;
    }
    this.loading = true;
    const params: Record<string, any> = {
      'per_page': 40,
    };

    this.productsService.productList(
      `customers/${this.customer?.id}/products`, params
    ).subscribe(res => {
      if (res) {
        const data = res.data;
        const googleProduct = data ? data?.find(p => p.abbreviation === 'GMB' && p.locked === false) : null;
        if (googleProduct && googleProduct != null && googleProduct?.tier === 'ultra') {
          this.globalsService.setIsGMBAllowed(true);
        }
        this.loading = false;
      }
    }, err => {
      this.loading = false;
    })

  }

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    // const status = file.status;
    // if (status !== 'uploading') {
    //   console.log(file, fileList);
    // }
    // if (status === 'done') {
    //   this.msg.success(`${file.name} file uploaded successfully.`);
    // } else if (status === 'error') {
    //   this.msg.error(`${file.name} file upload failed.`);
    // }
  }

  get showGMB() {
    return this.globalsService.getIsGMBAllowed();
  }

  onInput(value: string): void {
    this.hashtags = value.match(/#\S+/g) || [];
    if (this.hashtags.length > 30) {
      this.isHashtagLimit = true;
    } else {
      this.isHashtagLimit = false;
    }
  }

  handlePreventDefault(evt){
    evt = (evt) ? evt : window.event;
    if(this.hashtags.length >= 30 && evt.key == '#'){
      evt.preventDefault();
    }
  }

  toggleUploadMode(): void {
    this.outsideClick = true;
    if (this.image || this.video) {
      if (this.uploadMultiple === true) {
        this.confirmToggleMultiImageUpload(
          'This action will delete all your uploaded image or video. Are you sure you want to proceed?'
        ).then((result) => {
          if (result) {
            this.uploadMultiple = false;
            this.resetContents();
          } else {
            this.isCancelled = false;
          }
        });
      } else {
        this.confirmToggleMultiImageUpload(
          'This action will delete all your uploaded image. Are you sure you want to proceed?'
        ).then((result) => {
          if (result) {
            this.uploadMultiple = true;
            this.resetContents();
          } else {
            this.isCancelled = false;
          }
        });
      }
    }else{
      this.isCancelled = false;
      this.uploadMultiple = !this.uploadMultiple;
      this.outsideClick = false;
    }
  }

  resetContents() {
    if(this.video && this.content.url === undefined){
      this.isCancelled = true;
    }
    this.branded = false;
    this.content.url = undefined;
    this.uploadedFiles = [];
    this.fileUploaded = false;
    this.video = false;
    this.image = false;
    this.blockedUpload = false;
    this.removeAllFiles();
  }

  confirmToggleMultiImageUpload(content : String): Promise<boolean> {
    return new Promise((resolve) => {
      const confirmModal = this.modalService.confirm({
        nzTitle: 'Confirm',
        nzContent: content.toString(),
        nzOkText: 'Yes',
        nzCancelText: 'No',
        nzMaskClosable: true,
        nzOnOk: () => {
          resolve(true);
          this.outsideClick = false;
        },
        nzOnCancel: () => {
          resolve(false);
          this.outsideClick = false;
        }
      });
    });
  }
  setScheduledPostAndCheckOutsideClick(event: Event) {
    this.isScheduledPost = true;
    event.stopPropagation();
  }

  removeImageFromParent(url: string) {
    this.uploadedFiles = this.uploadedFiles.filter(image => image.url !== url);
    if(this.uploadedFiles.length === 0){
      this.fileUploaded = false; 
    }
  }

  removeImageOrVideoFromSingleUpload( event: Event) {
    event.stopPropagation();
    this.content.url = undefined;
    this.video = false;
    this.image = false;
    this.fileUploaded = false;
  }
}
