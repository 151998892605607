import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Content } from 'src/app/vos/content/content';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import {Subject} from 'rxjs';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */
export class PostsServiceConfig {
  uri = '';
}

@Injectable()
/**
 * Service class.
 */
export class PostsService extends CustomerResourceService<any> {
  public endpoint = 'posts';
  public data_key = 'post';
  private subject = new Subject<any>();
  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Content, http, authService);
  }
}
