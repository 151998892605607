import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Content } from 'src/app/vos/content/content';
import { BreadCrumService } from '../../../services/breadcrum/bread-crum.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ContentReceiptDialogComponent } from '../../../shared/content-receipt-dialog/content-receipt-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { AnnouncementAttributes } from 'src/app/shared/interfaces/announcemet-attributes';
import { ContentsService } from 'src/app/services/contents/contents.service';
import { NgForm } from '@angular/forms';
import { ProductStyle, Product } from 'src/app/vos/product/product';
import { ProductsService } from 'src/app/services/products/product-api.service';
import { Customer } from 'src/app/vos/customer/customer';
import { ContentFormComponent } from 'src/app/shared/content-form/content-form.component';
import { GlobalsService } from '../../../services/globals/globals.service';
import { ConnectLinkedinService } from '../../../services/connect-linkedin/connect-linkedin.service';
import { CustomersService } from '../../../services/customers/customers.service';

@Component({
  selector: 'app-create-announcement',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateAnnouncementComponent implements OnInit, AfterViewInit {
  content = new Content();
  announcement = new AnnouncementAttributes();
  announcementType;
  announcementContentType;
  data = {
    images: [],
  };
  showUpto = 5;
  showSchedule = false;
  submitted = false;
  isVideo = false;
  socialConfig;
  integration;
  linkedinStatus = false;
  linkedinAutopost = false;
  ishashTagLimit = false;
  textLength = 0;
  categoryMap = {
    testimonial_videos: 'TS',
    testimonial_flyers: 'TSF',
    announcement_video: 'AV',
  };
  uid = this.globalService.randomString();
  galleryImage: any;
  @ViewChild('testimonialForm') form: NgForm;
  @ViewChild('contentForm') contentForm: ContentFormComponent;
  current = 0;
  requiredFields = [
    'CTA',
    'Message'
  ];
  isPhotoValid = false;
  isGalleryValid = false;
  valid = false;
  styles: ProductStyle[] = [];
  product: Product;
  customer: Customer;
  contentSubject: Customer;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public message: NzMessageService,
    private modalService: NzModalService,
    private breadcrumservce: BreadCrumService,
    private authService: AuthenticationService,
    private contentService: ContentsService,
    private productService: ProductsService,
    private globalService: GlobalsService,
    private notification: NzNotificationService,
    private customerService: CustomersService,
    private linkedInService: ConnectLinkedinService,

  ) { }

  ngAfterViewInit() {
    this.form.valueChanges.subscribe(() => {
      this.valid = this.form.valid;
    });
    this.setSocialContentConfig();
  }
  ngOnInit() {
    this.data.images.length = 5;
    // Get type of testimonial from Url
    const routerUrl = this.router.url;
    const routerUrlHash = routerUrl.split('/');
    // this.testimonialType = routerUrlHash[2];
    this.announcementType = routerUrlHash[3]?.indexOf('announcement') > -1 ? routerUrlHash[3] : routerUrlHash[3];
    let testiType = this.announcementType.split('_');
    testiType = testiType.length > 1 ? testiType[1] : 'Video';
    testiType = testiType[0].toUpperCase() + testiType.slice(1);
    this.announcementContentType = testiType;

    this.contentSubject = this.authService.currentCustomerValue;
    this.route.params.subscribe(params => {
      this.customer = this.authService.currentCustomerValue;

      if (!this.customer) {
        this.authService.logout();
        this.router.navigate(['/login']);
        return;
      }
      const endpoint = `customers/${this.customer?.id}/products`;
      // use testimonialType to serch dynamically
      const searchParams = { 'q[name_eq]': this.announcementType };
      this.content.contentable_id = +this.route.snapshot.params['id'];
      this.productService.productList(endpoint, searchParams).subscribe(p => {
        if (p) {
          this.contentSubject.products = p.data;
          const products = this.contentSubject?.products?.map((product: Product) => new Product(product));
          // use testimonialType to filter products dynamically
          this.product = products?.find((prod) => prod.name === this.announcementType);
          this.isVideo = this.product?.media_type === 'video';
          if (!this.socialConfig) {
            this.socialConfig = { hideGoogle: this.isVideo };
          }
          this.socialConfig.hideGoogle = this.isVideo;
          if (this.product && this.product.parent_type.includes('customer')) {
            this.content.contentable_type = 'Customer';
            this.content.contentable_id = this.authService.currentCustomerValue?.id;
            this.fetchStyles();
          }
        }
      });
    });
    this.breadcrumservce.set_breadcrum();
    // this.breadcrumservce.push_breadcrum({ name: `Testimonial ${this.testimonialContentType}` });
    this.content.category = this.categoryMap[this.announcementType];
  }

  handleImagesChange($event) {
    this.data.images = $event;
    const currentLength = this.data.images.filter(img => img.id).map(img => img.id).length;
    if (currentLength < 5) {
      this.showUpto = currentLength;
    } else if (currentLength === 5) {
      this.showUpto = currentLength;
    }
  }

  create(platforms?, skip_post?) {
    this.content.extra_attributes = this.announcement;
    this.content.image_ids = this.data.images.slice(0, 5).filter(img => img.id).map(img => img.id);
    // if(this.content.extra_attributes.Message) {
    //   this.content.extra_attributes.Message =  '"' + this.content.extra_attributes.Message + '"';
    // }
    const contentPayload: any = this.content;
    if (platforms && platforms.length) {
      contentPayload.platforms = platforms;
    }
    if (skip_post) {
      contentPayload.skip_post = true;
    }
    this.contentService?.create(contentPayload).subscribe(response => {
      this.notification?.create(
        'success',
        'Created',
        'Content has successfully been created'
      );
      this.router.navigateByUrl(`/content?new=${response.id}`);
      // this.receipt();
    }, err => {
      this.message?.remove();
      this.message?.create('error', err.message);
    });
  }

  finalSubmit() {
    this.submitted = true;
    const platforms = [];
    // if(this.showSchedule && !this.isScheduleFormValid) {
    //   return;
    // }

    if (this.socialConfig && this.socialConfig.facebook) {
      platforms.push('facebook');
    }
    if (this.socialConfig && this.socialConfig.linkedin) {
      platforms.push('linkedin');
    }

    if (this.socialConfig && this.socialConfig.google) {
      platforms.push('google_business');
    }
    if (this.socialConfig && this.socialConfig.youtube) {
      platforms.push('youtube');
    }
    if (this.socialConfig && this.socialConfig.tiktok) {
      platforms.push('tiktok');
    }
    if (platforms.length === 0) {
      this.message?.remove();
      this.message?.create('error', 'Please select platform!');
    } else {
      this.create(platforms);
    }
  }

  render() {
    this.submitted = true;
    if (this.showSchedule && !this.isScheduleFormValid) {
      return;
    }
    this.create(false, true);
  }

  back() {
    this.router.navigate([
      '/'
    ]);
  }

  setAnnouncementPhoto(image, index) {
    if (image) {
      this.data.images[index] = image.id;
      if (this.data.images.length < 5) {
        this.data.images.length++;
      }
      // this.isPhotoValid =  true;
    }
  }
  previous() {
    this.current -= 1;
  }
  next() {
    this.current += 1;
    this.fetchStyles();
  }
  fetchStyles() {
    this.productService.styles(this.product, this.contentSubject)
      .subscribe(
        res => {
          if (res) {
            this.styles = res.data;
            if (this.styles.length === 1) {
              this.content.style_name = this.styles[0].effective_name;
              this.content.style_id = this.styles[0].id;
              this.styles[0].selected = true;
            }
          }
        }
      );
  }
  valueChange(value) {
    this.textLength = value.length;
  }

  fetch_linkedin_autopost_settings() {
    this.customerService.getLinkedin().subscribe((c) => {
      if (c && c.length > 0) {
        this.linkedinStatus = true;
        this.linkedInService.getAutopost().subscribe(int => {
          this.integration = int.data[0];
          this.linkedinAutopost = this.integration.auto_posting;
          this.socialConfig.linkedin = this.linkedinAutopost;
          this.socialConfig.facebook = this.customer?.settings?.auto_posting;
        });
      }
    });
  }

  setSocialContentConfig() {
    this.fetch_linkedin_autopost_settings();
    this.socialConfig = { content: this.content, facebook: false, linkedin: false };
    this.socialConfig.facebook = this.customer?.settings?.auto_posting;
  }

  get isScheduleFormValid() {
    return (this.socialConfig.facebook || this.socialConfig.linkedin) &&
      this.content.scheduled_for
  }

  hashTagLimit(value: boolean) {
    this.ishashTagLimit = value;
  }
}
